import React from "react";
import { connect } from "react-redux";
import { withPage } from "../PageContainer";
import ActionCreator from "../ActionCreator";
import * as Ant from "antd";
const queryString = require("query-string");

class AuthResultPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
    };
  }

  async componentDidMount() {
    let { location, appActions, navActions } = this.props;

    try {
      const queryParams = queryString.parse(location.search);
      window.localStorage.setItem("token", queryParams.token);
      let result = await appActions.autoLogin();
      if (result) {
        navActions.push("/profile");
        return;
      }
    } catch (ex) {
      console.warn(ex);
    }

    this.setState({
      error: "Fail to login",
      loading: false,
    });
  }

  render() {
    const { loading, error } = this.state;

    return (
      <div>
        {loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              padding: 20,
            }}
          >
            <Ant.Spin />
            <div style={{ marginLeft: 10 }}>
              Trying to login, please wait...
            </div>
          </div>
        )}

        {error && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              padding: 20,
            }}
          >
            <div style={{ marginLeft: 10 }}>{error}</div>
          </div>
        )}
      </div>
    );
  }
}

export default withPage(connect(null, ActionCreator)(AuthResultPage));
